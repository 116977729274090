import { getAuthorizationHeader, getWorkfrontRootApiUrl } from './configuration'
import { getRedrockHeaders, getRequestIdHeaders } from './headers.js'
import { redirectToLogin, shouldRedirectToLogin } from './redirectToLogin.js'

/**
 * @callback WXhrProgressListener
 * @param {ProgressEvent<XMLHttpRequestEventTarget>} evt
 * @returns {void}
 */

/**
 * @typedef {Object} WXhrOptions
 * @property {string} [method]   request method
 * @property {Document | BodyInit | null} body
 * @property {Object} [headers]   request headers
 * @property {AbortSignal} [signal]   a signal for aborting in-flight request
 * @property {WXhrProgressListener} [onProgress]   an event listener for 'progress' events
 */

/**
 * Wrapped xhr call which returns a promise which
 *   is rejected and user is redirected to login page if user is not authenticated,
 *   is rejected if request fails,
 *   is resolved with data received from endpoint when request succeeds.
 * @param {string} url   endpoint url to make the request to
 * @param {WXhrOptions} [options]
 * @returns {Promise<unknown>}
 */
export async function wxhr(url, options = {}) {
  const fullUrl = getWorkfrontRootApiUrl() + url

  const authorizationHeaderValue = getAuthorizationHeader()
  const authorizationHeader = authorizationHeaderValue
    ? { Authorization: authorizationHeaderValue }
    : {}

  return new Promise((resolve, reject) => {
    let { headers, method = 'GET' } = options
    const request = new XMLHttpRequest()
    if (options.signal) {
      options.signal.addEventListener(
        'abort',
        () => {
          request.abort()
        },
        { once: true },
      )
    }
    headers = {
      ...authorizationHeader,
      ...getRequestIdHeaders(),
      ...getRedrockHeaders(),
      ...headers,
    }
    if (options.onProgress) {
      request.upload.addEventListener('progress', options.onProgress)
    }

    request.addEventListener('load', () => {
      if (shouldRedirectToLogin(request)) {
        reject(redirectToLogin())
      } else if (request.readyState === 4) {
        if (isJSON(request.getResponseHeader('Content-Type'))) {
          try {
            resolve(JSON.parse(request.responseText || '{}'))
          } catch (error) {
            reject(error)
          }
        } else {
          resolve(request.responseText)
        }
      } else {
        throw new Error(request.statusText || 'Something went wrong')
      }
    })

    request.addEventListener('error', () => {
      reject(request.response)
    })

    request.withCredentials = fullUrl === url
    request.open(method, fullUrl)

    Object.entries(headers).forEach(([key, value]) => {
      request.setRequestHeader(key, value)
    })

    request.send(options.body)
  })
}

const isJSON = (contentType) =>
  contentType && contentType.indexOf('application/json') !== -1
